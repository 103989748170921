import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import { Card, Text, Link } from 'theme-ui'
import Section from '@components/Section'
import TextList from '@components/TextList'

const styles = {
  link: {
    display: `inline-flex`,
    color: `heading`,
    fontWeight: `medium`,
    ':visited': {
      color: `heading`
    },
    svg: {
      fill: `omegaDarker`,
      height: 24,
      ml: 2
    }
  }
}
const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="IKiBlog Terms & Conditions Policy"
          subheader='Your Privacy is at the Heart of our Concerns'
        />
      <Divider />
      <Section aside title='Our Commitment' {...props}>

        <Card variant='paper'>
          <Text variant='p'>
          It is I Kimera, La Macchia Digital Advisory Services&#39; policy to respect your privacy and comply with
          any applicable law and regulation regarding any personal information
          we may collect about you, including across our website,{" "}
          <Link textDecoration="underline" href="https://ikimera.com/blog">
            https://ikimera.com/blog
          </Link>
          , and other sites we own and operate.{" "}    
          </Text>
          <Text variant='p'> This policy is effective as of 14 December 2022 and was last updated
          on January 9 2023.{" "}
          </Text>
          <Text variant='h3'>
            Photos for blog posts illustrations and of authors
          </Text> 
          <Text variant='p'>
            To share and/or upload photos to this site (IKiBlog), you must ensure that they are of
            yourself, that you own the rights to them, and that they are not naked
            or pornographic. We are not responsible for any harm that may result
            from uploading photos that do not meet these criteria.
          </Text>
          <Text variant='h3'>
            Information We Collect
          </Text>
          <Text variant='p'>
            Information we collect includes both information you knowingly and
            actively provide us when using or participating in any of our services
            and promotions, and any information automatically sent by your devices
            in the course of accessing our products and services.{" "}
          </Text>
          <Text variant='h4'>
            Log Data
          </Text>
          <Text variant='p'>
            When you visit our website, our servers may automatically log the
            standard data provided by your web browser. It may include your
            device’s Internet Protocol (IP) address, your browser type and
            version, the pages you visit, the time and date of your visit, the
            time spent on each page, other details about your visit, and technical
            details that occur in conjunction with any errors you may encounter.{" "}
          </Text>
          <Text variant='p'>
            Please, be aware that while this information may not be personally
            identifying by itself, it may be possible to combine it with other
            data to personally identify individual persons.{" "}
          </Text>
          <Text  variant='h4'>
            Personal Information
          </Text>
          <Text variant='p'>
            We may ask for personal information which may include one or more of
            the following:{" "}
          </Text>
          <li>Email</li>
          <li>Contact Form</li>
          <li>Phone</li>
          <li>Post Mail</li>
          <li>Virtual Meeting</li>
          <Text variant='p'></Text>
          <Text variant='h4'>
            Legitimate Reasons for Processing Your Personal Information
          </Text>
          <Text variant='p'>
            We only collect and use your personal information when we have a
            legitimate reason for doing so. In which instance, we only collect
            personal information that is reasonably necessary to provide our
            services to you.{" "}
          </Text>
          <Text variant='h4'>
            Collection and Use of Information
          </Text>
          <Text variant='p'>
            By accessing and using IKiBlog and other areas of the website (ikimera.com), you are also consuming the Gatsby Cloud
            services and thereby accepting the terms of use outlined at
            <Link ml={1} href="https://www.gatsbyjs.com/privacy-policy">
              https://www.gatsbyjs.com/privacy-policy
            </Link>
            . 
            The information you share with us via our contact forms are being forwarded to us through Getform.io endpoints. By completing using
            our forms, you consent with the terms of use outlined at. https://getform.io/legal/privacy-policy 
            Please review these terms carefully before continuing to browse IKiBlog. If you do not agree to these terms, 
            please refrain from sharing private information with us through our website.
          </Text>
          <Text variant='p'>
            We may collect personal information from you when you do any of the
            following on our website:{" "}
          </Text>
          <li>Use a mobile device or web browser to access our content</li>
          <li>Contact us via email, social media, or on any similar technologies</li>
          <li>When you mention us on social media</li>
          <Text variant='p'></Text>
          <Text variant='p'>
            We may collect, hold, use, and disclose information for the following
            purposes, and personal information will not be further processed in a
            manner that is incompatible with these purposes:{" "}
          </Text>
          <li> to enable you to access and use our website, associated
              applications, and associated social media platforms </li>
              <Text variant='p'></Text>
          <Text variant='p'>
            Please be aware that we may combine information we collect about you
            with general information or research data we receive from other
            trusted sources.{" "}
          </Text>
          <Text variant='h4'>
            Security of Your Personal Information
          </Text>
          <Text variant='p'>
            When we collect and process personal information, and while we retain
            this information, we will protect it within commercially acceptable
            means to prevent loss and theft, as well as unauthorized access,
            disclosure, copying, use, or modification.{" "}
          </Text>
          <Text variant='p'>
            Although we will do our best to protect the personal information you
            provide to us, we advise that no method of electronic transmission or
            storage is 100% secure, and no one can guarantee absolute data
            security. We will comply with laws applicable to us in respect of any
            data breach.{" "}
          </Text>
          <Text variant='p'>
            You are responsible for selecting any password and its overall
            security strength, ensuring the security of your own information
            within the bounds of our services.{" "}
          </Text>
          <Text variant='h4'>
            How Long We Keep Your Personal Information
          </Text>
          <Text variant='p'>
            We keep your personal information only for as long as we need to. This
            time period may depend on what we are using your information for, in
            accordance with this privacy policy. If your personal information is
            no longer required, we will delete it or make it anonymous by removing
            all details that identify you.{" "}
          </Text>
          <Text variant='p'>
            However, if necessary, we may retain your personal information for our
            compliance with a legal, accounting, or reporting obligation or for
            archiving purposes in the public interest, scientific, or historical
            research purposes or statistical purposes.{" "}
          </Text>
          <Text variant='p'>
            You may request us to delete or directly delete (when the feature will be available) all of the photos and text 
            associated with the blog articles you published on IKiBlog.
          </Text>
          <Text variant='p'>
            In the future (when the feature will be available), to request that your account and all associated data be deleted,
            please send an email to{" "}
            <Link href="mailto:ico@ikimera.com">ico@ikimera.com</Link>. Please
            note that by deleting your account, you will no longer have access to
            any of the data or content associated with your account.
          </Text>

          <Text variant='h3'>
            Children’s Privacy
          </Text>
          <Text variant='p'>
            We do not aim any of our products or services directly at children
            under the age of 13, and we do not knowingly collect personal
            information about children under 13.{" "}
          </Text>
          <Text variant='h3'>
            Disclosure of Personal Information to Third Parties
          </Text>
          <Text variant='p'>We may disclose personal information to: </Text>
          <li>a parent, subsidiary, or affiliate of our company</li>
          <li>third party service providers for the purpose of enabling them to
              provide their services, for example, IT service providers, data
              storage, hosting and server providers, advertisers, or analytics
              platforms</li>
          <li>our employees, contractors, and/or related entities</li>
          <li>our existing or potential agents or business partners</li>
          <li>sponsors or promoters of any competition, sweepstakes, or promotion
              we run</li>
          <li>courts, tribunals, regulatory authorities, and law enforcement
              officers, as required by law, in connection with any actual or
              prospective legal proceedings, or in order to establish, exercise,
              or defend our legal rights</li>
          <li>third parties, including agents or sub-contractors, who assist us in
              providing information, products, services, or direct marketing to
              you third parties to collect and process data</li>
          <Text variant='p'></Text>
          <Text variant='h3'>
            International Transfers of Personal Information
          </Text>
          <Text variant='p'>
            The personal information we collect is stored and/or processed where
            we or our partners, affiliates, and third-party providers maintain
            facilities. Please be aware that the locations to which we store,
            process, or transfer your personal information may not have the same
            data protection laws as the country in which you initially provided
            the information. If we transfer your personal information to third
            parties in other countries: (i) we will perform those transfers in
            accordance with the requirements of applicable law; and (ii) we will
            protect the transferred personal information in accordance with this
            privacy policy.{" "}
          </Text>
          <Text variant='h3'>
            Your Rights and Controlling Your Personal Information
          </Text>
          <Text variant='p'>
            You always retain the right to withhold personal information from us,
            with the understanding that your experience of our website may be
            affected. We will not discriminate against you for exercising any of
            your rights over your personal information. If you do provide us with
            personal information you understand that we will collect, hold, use
            and disclose it in accordance with this privacy policy. You retain the
            right to request details of any personal information we hold about
            you.{" "}
          </Text>
          <Text variant='p'>
            If we receive personal information about you from a third party, we
            will protect it as set out in this privacy policy. If you are a third
            party providing personal information about somebody else, you
            represent and warrant that you have such person’s consent to provide
            the personal information to us.{" "}
          </Text>
          <Text variant='p'>
            If you have previously agreed to us using your personal information
            for direct marketing purposes, you may change your mind at any time.
            We will provide you with the ability to unsubscribe from our
            email-database or opt out of communications. Please be aware we may
            need to request specific information from you to help us confirm your
            identity.{" "}
          </Text>
          <Text variant='p'>
            If you believe that any information we hold about you is inaccurate,
            out of date, incomplete, irrelevant, or misleading, please contact us
            using the details provided in this privacy policy. We will take
            reasonable steps to correct any information found to be inaccurate,
            incomplete, misleading, or out of date.{" "}
          </Text>
          <Text variant='p'>
            If you believe that we have breached a relevant data protection law
            and wish to make a complaint, please contact us using the details
            below and provide us with full details of the alleged breach. We will
            promptly investigate your complaint and respond to you, in writing,
            setting out the outcome of our investigation and the steps we will
            take to deal with your complaint. You also have the right to contact a
            regulatory body or data protection authority in relation to your
            complaint.{" "}
          </Text>
          <Text variant='h3'>
            Use of Cookies
          </Text>
          <Text variant='p'>
            We use &ldquo;cookies&rdquo; to collect information about you and your
            activity across our site. A cookie is a small piece of data that our
            website stores on your computer, and accesses each time you visit, so
            we can understand how you use our site. This helps us serve you
            content based on preferences you have specified.{" "}
          </Text>
          <Text variant='h3'>
            Limits of Our Policy
          </Text>
          <Text variant='p'>
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and policies
            of those sites, and cannot accept responsibility or liability for
            their respective privacy practices.{" "}
          </Text>
          <Text variant='h3'>
            Changes to This Policy
          </Text>
          <Text variant='p'>
            At our discretion, we may change our privacy policy to reflect updates
            to our business processes, current acceptable practices, or
            legislative or regulatory changes. If we decide to change this privacy
            policy, we will post the changes here at the same link by which you
            are accessing this privacy policy.{" "}
          </Text>
          <Text variant='p'>
            If required by law, we will get your permission or give you the
            opportunity to opt in to or opt out of, as applicable, any new uses of
            your personal information.{" "}
          </Text>
          <Text variant='h3'>
            Contact Us
          </Text>
          <Text variant='p'>
            For any questions or concerns regarding your privacy, you may contact
            us using the following details:{" "}
            <Link href="mailto:ico@ikimera.com">ico@ikimera.com</Link>
          </Text>

 
        </Card>
      </Section>
 
        <Divider />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
